body {
  background-color: rgb(234, 234, 234) !important;
}

/*timepicker style*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(250, 2, 56) 0%,
    rgb(151, 116, 233) 69%,
    rgb(171, 150, 230) 89%
  ) !important;
  height: 100vh;
}

/* .masthead.segment {
  min-height: 700px;
  padding: 1em 0 !important;
} */

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/* .footer.segment {
  padding: 5em 0;
} */

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/

/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(250, 2, 56) 0%,
    rgb(151, 116, 233) 69%,
    rgb(171, 150, 230) 89%
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 7em;
}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.ui.menu .item > img:not(.ui) {
  margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item > .button {
  margin-left: 0.5em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 2em !important;
}

/* dropzone styles */
.dropzone {
  border: dashed 3px #eee;
  border-radius: 5px;
  padding-top: 30px;
  text-align: center;
  /* min-height: 236px; */
}

.image-gallery-slide img {
  width: 100%;
  height: 385px;

  object-fit: fill;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  height: 100vh;
}

@media only screen and (max-width: 758px) {
  .center-infinite-scroll-loading {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media only screen and (max-width: 1000px) {
  .image-gallery-slide img {
    width: 100%;
    height: 320px;

    object-fit: fill;
    overflow: hidden;
    object-position: center center;
  }
}

.center-image-preview {
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: center !important;
}
